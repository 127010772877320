<template>
  <sgv-table
    ref="docList"
    :rKey="rKey"
    :items="items"
    :headers="headers"
    :filter.sync="filter"
    :options.sync="options">

    <template slot-scope="{item, hidden}">
      <tr>
        <td v-if="hidden.code">
          <router-link
            class="text-decoration-none"
            :to="toDetail(0, item.contactId)">
            <span>
              {{item.code}}
            </span>
          </router-link>
          <div class="">
            <small class="text-primary">
              {{ item.billedAt | date }}
            </small>
          </div>
        </td>
        <td v-if="hidden.name">
          {{item.name}}

          <div v-if="item.remark">
            <small class="text-primary" >
              {{item.remark}}
            </small>
          </div>

          <div class="">
            <small>
              <span class="text-info">ยอดรวม: {{item.total | comma}}</span> |
              <span class="text-danger">ค้างชำระ: {{item.remaining | comma}}</span> |
              <span class="text-warning">รอการชำระ: {{item.pending | comma}}</span> |
              <span class="text-success">ชำระแล้ว: {{item.paid | comma}}</span>
            </small>
          </div>
        </td>
      </tr>
    </template>

    <router-link
      slot="action"
      v-if="$auth.hasRole(`doc:${docType}:add`)"
      :to="toDetail(0, 0)">
      <button
        type="button"
        class="btn btn-link text-success">
        เพิ่ม
      </button>
    </router-link>
  </sgv-table>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import { LIST_PAYABLE } from './graph'

export default {
  mixins: [retainMixin],
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      detailView: `Doc${this.$form.capitalize(this.docType)}Detail`,
      rKey: `Doc${this.$form.capitalize(this.docType)}ListPayable`,
      headers: [
        {text: 'รหัส', value: 'code'},
        {text: 'รายละเอียด', value: 'name'}
      ],
      filter: {
        limit: 0,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['code', 'name'],
        column: null,
        search: null,
        toolbar: null,
      },
      rFields: ['filter'],
      docs: [],
    }
  },
  computed: {
    items () {
      return this.docs.map(v => {
        return {
          id: v.id,
          code: v.code,
          name: v.name,
          remark: v.remark,
          contactId: v.contactId,
          billedAt: v.billedAt,
          ...this.getSummerize(v)
        }
      })
    }
  },
  apollo: {
    docs: {
      query () {
        return LIST_PAYABLE(this.templateType)
      },
      variables() {
        this.setRetaining()
        return {
          docType: this.docType,
          q: this.getFilter(this.filter)
        }
      },
      debounce: 150,
      fetchPolicy: 'network-only'
    }
  },
  methods: {
    getFilter (v) {
      return {
        params: {
          search: v.params ? v.params.search : ''
        }
      }
    },
    toDetail (id, contactId) {
      return {
        name: this.detailView,
        params: {docId: id},
        query: {contactId}
      }
    },
    getSummerize (item) {
      return item.orders.reduce((t,v) => {
        t.total += v.total
        t.remaining += v.remaining
        t.pending += v.pending
        t.paid += v.paid
        return t
      }, {total: 0, remaining: 0, pending: 0, paid: 0})
    }
  },
  created () {
    this.$store.commit('path/setCurrent', {to: this.$route, group: this.group})
  }
}
</script>

<style lang="css" scoped>
</style>
